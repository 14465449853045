import React from "react";
import Layout from "../../containers/Layout";
import { Contact } from "../../components";
import FaqProducts from "../../components/FaqsComponents/FaqProducts";

export default function General() {
  return (
    <Layout title={"FAQs"}>
      <FaqProducts />
      <Contact />
    </Layout>
  );
}
